import React, { Component } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Layout from "../layout";
import config from "../../data/SiteConfig";
import DownloadAPP from "../components/DownloadAPP/DownloadAPP";


class Error404 extends Component {
  render() {
    return (
      <Layout>
        <div className="about-container">
          <HelmetProvider>
            <Helmet title={`خطای ۴۰۴ | ${config.siteTitle}`} />
          </HelmetProvider>
          <h1>Error404</h1>
        </div>
        <DownloadAPP />
      </Layout>
    );
  }
}

export default Error404;
